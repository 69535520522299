import React, { useState, useRef, useEffect } from "react";
import { PDFDocument } from 'pdf-lib';

import {
  TextField,
  Button,
  Box,
  Grid,
  Typography,
} from "@mui/material";

// Your TAGS_MAP and Tags array remain the same
const TAGS_MAP = new Map([
    ["DAA", "FULL NAME"],
    ["DAC", "FIRST NAME"],
    ["DCT", "FIRST NAME"],
    ["DBP", "FIRST NAME"],
    ["DBG", "FIRST NAME"],
    ["DAD", "MIDDLE NAME"],
    ["DAB", "LAST NAME"],
    ["DCS", "LAST NAME"],
    ["DBO", "LAST NAME"],
    ["DCU", "Name Suffix"],
    ["DBN", "Name Suffix"],
    ["DAG", "STREET ADDRESS"],
    ["DAH", "STREET ADDRESS"],
    ["DAL", "STREET ADDRESS"],
    ["DAI", "CITY"],
    ["DAJ", "STATE"],
    ["DAK", "ZIPCODE"],
    ["DCG", "COUNTRY"],
    ["DAQ", "ID NUMBER"],
    ["DCJ", "ID NUMBER"],
    ["DBJ", "ID NUMBER"],
    ["DCK", "INVENTORY CONTROL"],
    ["DCA", "CLASS"],
    ["DCB", "RESTRICTIONS"],
    ["DCD", "ENDORSEMENTS"],
    ["DCF", "DOCUMENT DISCRIMINATOR"],
    ["DCH", "VEHICLE CODE"],
    ["DBA", "EXPIRATION DATE"],
    ["DBB", "DATE OF BIRTH"],
    ["DCI", "PLACE OF BIRTH"],
    ["DBC", "GENDER"],
    ["DBD", "ISSUED DATE"],
    ["DAU", "HEIGHT"],
    ["DCE", "WEIGHT"],
    ["DAW", "WEIGHT"],
    ["DAY", "EYE COLOR"],
    ["DAZ", "HAIR COLOR"],
    ["ZWA", "CONTROL NUMBER"],
    ["ZWB", "WA SPECIFIC ENDORSEMENT"],
    ["ZWC", "TRANSACTION TYPE"],
    ["ZWD", "UNDER 18 UNTIL"],
    ["ZWE", "UNDER 21 UNTIL"],
    ["ZWF", "REVISION DATE"],
    ["DDE", "FAMILY NAME TRUNCATION"],
    ["DDF", "FIRST NAME TRUNCATION"],
    ["DDG", "MIDDLE NAME TRUNCATION"],
    ["DDA", "COMPLIANCE TYPE"],
    ["DDB", "CARD REVISION DATE"],
    ["DDD", "LIMITED INDICATOR"]
  ]);
  
  const Tags = [
    "DAA", "DAC","ZVZ", "DCT", "DBP", "DBG", "DAD", "DAB", "DCS", "DBO", "DCU",
    "DBN", "DAG", "DAH", "DAL", "DAI", "DAJ", "DAK", "DCG", "DAQ", "DCJ", "DBJ",
    "DCK", "DCA", "DCB", "DCD", "DCF", "DCH", "DBA", "DBB", "DCI", "DBC", "DBD",
    "DAU", "DCE", "DAW", "DAY", "DAZ", "ZWA", "ZWB", "ZWC", "ZWD", "ZWE", "ZWF",
    "DDE", "DDF", "DDG", "DDA", "DDB", "DDD"
  ];



const InfoForm = () => {


    const [inputValue, setInputValue] = useState("");
  
    const handleInputChange = (e) => {
      setInputValue(e.target.value);
    };

    const scannedInfoRef = useRef(null); // Create a ref for the Scanned info field
     // Focus the Scanned info text field on component mount
  useEffect(() => {
    if (scannedInfoRef.current) {
      scannedInfoRef.current.focus();
    }
  }, []);

  
   
    

    const handleRetrieveClick = () => {
        const updatedFormData = { ...formData }; // Copy current formData to update fields
        const extractedInfo = [];
      
        Tags.forEach((tag) => {
          // Create a regex that captures the value between tags
          const regex = new RegExp(
            `${tag}([A-Za-z0-9\\s]+?)(?=(?:${Tags.join('|')})|$)`,
            "g"
          );
          let match;
      
          // Find matches in the input value
          while ((match = regex.exec(inputValue)) !== null) {
            const tagName = TAGS_MAP.get(tag);
            const value = match[1].trim(); // Remove any extra spaces
            extractedInfo.push({ tag, tagName, value });
      
            // Check if the value is "NONE"
            if (value === "NONE") {
              // Look for another tag with the same name (i.e., non-"NONE" value) and refer it
              const otherTag = Tags.find((otherTag) => {
                const otherRegex = new RegExp(
                  `${otherTag}([A-Za-z0-9\\s]+?)(?=(?:${Tags.join('|')})|$)`,
                  "g"
                );
                const otherMatch = otherRegex.exec(inputValue);
                return otherMatch && otherMatch[1].trim() !== "NONE";
              });
      
              if (otherTag) {
                //const otherTagName = TAGS_MAP.get(otherTag);
                const otherValue = new RegExp(
                  `${otherTag}([A-Za-z0-9\\s]+?)(?=(?:${Tags.join('|')})|$)`,
                  "g"
                ).exec(inputValue)[1].trim(); // Get the non-None value from the other tag
      
                // Update the formData with the other tag's value
                updatedFormData[tagName] = otherValue;
              }
            } else {
              // Map tags to formData keys if value is not "NONE"
              switch (tagName) {
                case "DATE OF BIRTH":
                  if (value.length === 8) {
                    const month = value.slice(0, 2);
                    const day = value.slice(2, 4);
                    const year = value.slice(4);
                    updatedFormData.dateOfBirth = `${month}/${day}/${year}`;
                  } else {
                    updatedFormData.dateOfBirth = "Invalid Date";
                  }
                  break;
                case "STREET ADDRESS":
                  updatedFormData.streetAddress = value; // Update only street address without combining other components
                  break;
                case "CITY":
                  updatedFormData.city = value;
                  break;
                case "STATE":
                  updatedFormData.state = value;
                  break;
                case "ZIPCODE":
                  if (value.length === 9) {
                    updatedFormData.zipCode = `${value.slice(0, 5)}-${value.slice(5)}`;
                  } else {
                    updatedFormData.zipCode = value;
                  }
                  break;
                case "HEIGHT":
                  const inches = parseInt(value, 10);
                  const feet = Math.floor(inches / 12);
                  const remainingInches = inches % 12;
                  updatedFormData.height = `${feet} FT  ${remainingInches} INCH`;
                  break;
                case "ID NUMBER":
                  updatedFormData.idNumber = value;
                  break;
                case "FIRST NAME":
                  updatedFormData.firstName = value;
                  break;
                case "MIDDLE NAME":
                  updatedFormData.middle = value;
                  break;
                case "LAST NAME":
                  updatedFormData.lastName = value;
                  break;
                case "GENDER":
                  if (value === "1") {
                    updatedFormData.gender = "MALE";
                  } else if (value === "2") {
                    updatedFormData.gender = "FEMALE";
                  } else {
                    updatedFormData.gender = "OTHER";
                  }
                  break;
                case "EYE COLOR":
                  switch (value) {
                    case "BRO":
                      updatedFormData.eyeColor = "BROWN";
                      break;
                    case "BLU":
                      updatedFormData.eyeColor = "BLUE";
                      break;
                    case "GRN":
                      updatedFormData.eyeColor = "GREEN";
                      break;
                    case "HAZ":
                      updatedFormData.eyeColor = "HAZEL";
                      break;
                    case "GRY":
                      updatedFormData.eyeColor = "GRAY";
                      break;
                    case "AMB":
                      updatedFormData.eyeColor = "AMBER";
                      break;
                    default:
                      updatedFormData.eyeColor = "OTHER";
                      break;
                  }
                  break;
                case "WEIGHT":
                  updatedFormData.weight = value + " LB";
                  break;
                default:
                  break; // Handle other cases as needed
              }
            }
          }
        });
      
        setFormData(updatedFormData); // Update form data with extracted info
      };
      
      const fillPdfForm = async (formData) => {
        try {
          // Load the existing PDF template
          const existingPdfBytes = await fetch('/Lic.pdf').then(res => res.arrayBuffer());
      
          // Load the PDF into pdf-lib
          const pdfDoc = await PDFDocument.load(existingPdfBytes);
          const form = pdfDoc.getForm();  // Access the form fields         
           // Set a large font size

        // Fill in the form fields with data from formData
         form.getTextField('first').setText(formData.firstName);
         form.getTextField('midd').setText(formData.middle);
         form.getTextField('last').setText(formData.lastName);
         form.getTextField('dob').setText(formData.dateOfBirth);
         form.getTextField('eye').setText(formData.eyeColor);
         form.getTextField('Height').setText(formData.height);
         form.getTextField('Weight').setText(formData.weight);      
         form.getTextField('mail').setText(formData.streetAddress);
         form.getTextField('city').setText(formData.city);
         form.getTextField('state').setText(formData.state);
         form.getTextField('zip').setText(formData.zipCode);         
         form.getTextField('lic').setText(formData.idNumber);
    
          
          // Generate the filled PDF as bytes
          const pdfBytes = await pdfDoc.save();
      
          // Create a Blob object from the PDF bytes
          const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
      
          // Create a Blob URL
          const pdfUrl = URL.createObjectURL(pdfBlob);
      
          // Open the PDF in a new tab
          window.open(pdfUrl, '_blank');
        } catch (error) {
          console.error('Error filling PDF:', error);
        }
      };


  const [formData, setFormData] = useState({
    idNumber: "",
    firstName: "",
    middle: "",
    lastName: "",
    dateOfBirth: "",
    gender: "",
    eyeColor: "",
    height: "",
    weight: "",
    streetAddress: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    
  };

  const handleSave = () => {
    fillPdfForm(formData)

  };

  const handleClear = () => {
    setFormData({
      idNumber: "",
      firstName: "",
      middle: "",
      lastName: "",
      dateOfBirth: "",
      gender: "",
      eyeColor: "",
      height: "",
      weight: "",
      streetAddress: "",
    });
  };  

  return (
    <Box
      sx={{
        backgroundColor: "#282a36", // Dracula background
        minHeight: "10vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 3,
      }}
    >

      <Box
        sx={{
          maxWidth: 800,
          width: "100%",
          padding: 3,
          borderRadius: 2,
          backgroundColor: "#ffffff", // White form background
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)", // Subtle shadow
        }}
      >
      
      <Box sx={{ maxWidth: 800, width: "100%", padding: 3, borderRadius: 2, backgroundColor: "#ffffff", boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)" }}>
      <TextField
        fullWidth
        label="Scanned info"
        value={inputValue}
        onChange={handleInputChange}
        variant="outlined"
        inputRef={scannedInfoRef} // Assign the ref here
        InputLabelProps={{
          shrink: true,
          style: { textTransform: "uppercase", fontSize: "1.3rem", fontWeight: 700, color: "#000000" }
        }}
      />
      <Button
        variant="contained"
        onClick={handleRetrieveClick}
        sx={{ marginTop: 1, backgroundColor: "#007BFF", "&:hover": { backgroundColor: "#0056b3" } }}
      >
        Retrieve Info
      </Button>
   
    </Box>
        <Typography variant="h5" sx={{ mb: 2, textAlign: "center" ,padding:1}}>
        </Typography>
        <Grid container spacing={2}>
          {/* ID Number */}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="ID Number"
              name="idNumber"
              value={formData.idNumber}
              onChange={handleChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
                style: {
                  textTransform: "uppercase",
                  fontSize: "1.3rem",
                  fontWeight: 700,
                  color: "#000000",
                },
              }}
            />
          </Grid>

          {/* Name Fields */}
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="First Name"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
                style: {
                  textTransform: "uppercase",
                  fontSize: "1.3rem",
                  fontWeight: 700,
                  color: "#000000",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Middle"
              name="middle"
              value={formData.middle}
              onChange={handleChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
                style: {
                  textTransform: "uppercase",
                  fontSize: "1.3rem",
                  fontWeight: 700,
                  color: "#000000",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              label="Last Name"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
                style: {
                  textTransform: "uppercase",
                  fontSize: "1.3rem",
                  fontWeight: 700,
                  color: "#000000",
                },
              }}
            />
          </Grid>

          {/* Date and Place of Birth */}
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              label="Date of Birth"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
                style: {
                  textTransform: "uppercase",
                  fontSize: "1.3rem",
                  fontWeight: 700,
                  color: "#000000",
                },
              }}
            />
          </Grid>         

          {/* Additional Information */}
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              label="Gender"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
                style: {
                  textTransform: "uppercase",
                  fontSize: "1.3rem",
                  fontWeight: 700,
                  color: "#000000",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              label="Eye Color"
              name="eyeColor"
              value={formData.eyeColor}
              onChange={handleChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
                style: {
                  textTransform: "uppercase",
                  fontSize: "1.3rem",
                  fontWeight: 700,
                  color: "#000000",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Height"
              name="height"
              value={formData.height}
              onChange={handleChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
                style: {
                  textTransform: "uppercase",
                  fontSize: "1.3rem",
                  fontWeight: 700,
                  color: "#000000",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              fullWidth
              label="Weight"
              name="weight"
              value={formData.weight}
              onChange={handleChange}
              variant="outlined"
              InputLabelProps={{
                shrink: true,
                style: {
                  textTransform: "uppercase",
                  fontSize: "1.3rem",
                  fontWeight: 700,
                  color: "#000000",
                },
              }}
            />
          </Grid>      

          <Grid item xs={5}>
  <TextField
    fullWidth
    label="Street Address"
    name="streetAddress"
    value={formData.streetAddress}
    onChange={handleChange}
    variant="outlined"
    InputLabelProps={{
      shrink: true,
      style: {
        textTransform: "uppercase",
        fontSize: "1.3rem",
        fontWeight: 700,
        color: "#000000",
      },
    }}
  />
</Grid>
<Grid item xs={3}>
  <TextField
    fullWidth
    label="City"
    name="city"
    value={formData.city}
    onChange={handleChange}
    variant="outlined"
    InputLabelProps={{
      shrink: true,
      style: {
        textTransform: "uppercase",
        fontSize: "1.3rem",
        fontWeight: 700,
        color: "#000000",
      },
    }}
  />
</Grid>
<Grid item xs={2}>
  <TextField
    fullWidth
    label="State"
    name="state"
    value={formData.state}
    onChange={handleChange}
    variant="outlined"
    InputLabelProps={{
      shrink: true,
      style: {
        textTransform: "uppercase",
        fontSize: "1.3rem",
        fontWeight: 700,
        color: "#000000",
      },
    }}
  />
</Grid>
<Grid item xs={2}>
  <TextField
    fullWidth
    label="Zip Code"
    name="zipCode"
    value={formData.zipCode}
    onChange={handleChange}
    variant="outlined"
    InputLabelProps={{
      shrink: true,
      style: {
        textTransform: "uppercase",
        fontSize: "1.3rem",
        fontWeight: 700,
        color: "#000000",
      },
    }}
  />
</Grid>

          {/* Buttons */}
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Box display="flex" gap={2}>
              <Button
                variant="contained"
                onClick={handleSave}
                sx={{
                  flex: 1,
                  backgroundColor: "#007BFF",
                  "&:hover": { backgroundColor: "#0056b3" },
                }}
              >
                Print PDF Form
              </Button>
              <Button
                variant="outlined"
                onClick={handleClear}
                sx={{
                  flex: 1,
                  borderColor: "#FF6347",
                  color: "#FF6347",
                  "&:hover": { backgroundColor: "#ffe6e2" },
                }}
              >
                Clear
              </Button>
            </Box>
          </Grid>
        </Grid>     
      </Box>
    </Box>
  );
};

export default InfoForm;
