import React from 'react';
import InfoForme from './InfoForm';
import DisclaimerCard from './DisclaimerCard';


function App() { 

  return (
    <div style= {{textAlign : 'center'}}> 
    <InfoForme/>
    <DisclaimerCard/>
    </div>

  );
}

export default App;