import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

const DisclaimerCard = () => {
  return (
    <Box 
      sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        padding: 3 
      }}
    >
      <Card 
        sx={{ 
          maxWidth: 600, 
          borderRadius: 2, 
          backgroundColor: "#f8f9fa", 
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" 
        }}
      >
        <CardContent>
          <Typography 
            variant="h4" 
            sx={{
              textTransform:"uppercase",   
              textAlign: "center", 
              color: "#495057", 
              fontWeight: 800, 
              marginBottom: 1 
            }}
          >
            Disclaimer
          </Typography>
          <Typography 
            variant="h5" 
            sx={{
              textTransform:"uppercase", 
              textAlign: "center", 
              color: "#6c757d" 
            }}
          >
            This webpage is for testing and educational purposes only. 
            No data is saved or stored; all content is view-only.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default DisclaimerCard;
